import { IonCol, IonImg, IonRow } from "@ionic/react";

import { ONBOARDING_STEPS } from "../constants/ONBOARDING_STEPS";
import { useSelectedWorkType } from "../hooks/useSelectedWorkType";

interface Props {
  activeStep: (typeof ONBOARDING_STEPS)[keyof typeof ONBOARDING_STEPS];
  isScheduledAssessmentAvailableForOnboarding: boolean;
}

export function OnboardingHeader(props: Props) {
  const { activeStep, isScheduledAssessmentAvailableForOnboarding } = props;
  const { isPermanentPlacementsOnboarding, isFlexAndPermanentPlacementsOnboarding } =
    useSelectedWorkType();

  function getStepDetails() {
    switch (activeStep) {
      case ONBOARDING_STEPS.PERSONAL_INFORMATION:
        return {
          title: "Setting you up",
          description:
            "We just need a bit more information about you to show you the best shifts for you!",
        };
      case ONBOARDING_STEPS.PROFESSIONAL_INFORMATION: {
        let title = "One down, three to go";
        if (isPermanentPlacementsOnboarding) {
          title = "One down, two to go";
        } else if (isScheduledAssessmentAvailableForOnboarding) {
          title = "One down, four to go";
        }

        return {
          title,
          description:
            "You're going to need your license details for this step. Go grab that now if you don't have it handy. We'll wait.",
        };
      }
      case ONBOARDING_STEPS.CONTRACT_AND_PAY_INFORMATION:
        return {
          title: "Halfway there!",
          description:
            "Let's get set up to get paid. You're going to need your debit card or bank information!",
        };
      case ONBOARDING_STEPS.REQUIRED_DOCUMENTS:
        if (isPermanentPlacementsOnboarding) {
          return {
            title: "Almost there",
            description: "Let's verify your identity so you can start booking job interviews.",
          };
        }

        if (isFlexAndPermanentPlacementsOnboarding) {
          return {
            title: isScheduledAssessmentAvailableForOnboarding
              ? "All done with Stripe! Two to go"
              : "All done with Stripe! One last thing",
            description:
              "We require some documents to be uploaded and approved before you can start working. Don't worry, you probably have all of them already.",
          };
        }

        return {
          title: isScheduledAssessmentAvailableForOnboarding
            ? "All done with Stripe! Two to go"
            : "All done with Stripe! One last thing",
          description: isScheduledAssessmentAvailableForOnboarding
            ? "We require a set of documents and a skills assessment to be completed before we can place you on a shift. Don't worry, this should be quick."
            : "We require a set of documents to be completed and uploaded before we can place you in a shift. Don't worry, you probably have all of them already.",
        };
      case ONBOARDING_STEPS.SCHEDULE_SKILL_ASSESSMENT:
        return {
          title: "One last thing",
          description:
            "Now you only need to schedule your skills assessment. When you've passed, you're all set to start booking shifts.",
        };
      default:
        return {
          title: "",
          description: "",
        };
    }
  }

  const stepDetails = getStepDetails();

  const basePath = process.env.REACT_APP_BASE_PATH ?? "/";

  return (
    <IonRow>
      <IonCol>
        <div className="cbh-logoh-wrapper">
          <IonImg src={`${basePath}assets/logo/logo-horizontal.svg`} class="logo" />
        </div>
        <div className="form-heading">
          <h4>{stepDetails?.title}</h4>
          <p>{stepDetails?.description}</p>
        </div>
      </IonCol>
    </IonRow>
  );
}
