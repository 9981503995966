import { IonCol, IonIcon, IonRow } from "@ionic/react";

import { customCheckmark } from "../../../icons/customCheckmark";
import { ONBOARDING_STEPS } from "../constants/ONBOARDING_STEPS";
import { useSelectedWorkType } from "../hooks/useSelectedWorkType";

interface Props {
  activeStep: (typeof ONBOARDING_STEPS)[keyof typeof ONBOARDING_STEPS];
  isScheduledAssessmentAvailableForOnboarding: boolean;
}

export function OnboardingSteps(props: Props) {
  const { activeStep, isScheduledAssessmentAvailableForOnboarding } = props;
  const { isPermanentPlacementsOnboarding, isFlexAndPermanentPlacementsOnboarding } =
    useSelectedWorkType();

  function getDocumentsStepDescription(): string {
    if (isPermanentPlacementsOnboarding) {
      return "Verify your identity so you can start booking interviews";
    }

    if (isFlexAndPermanentPlacementsOnboarding) {
      return "Submit a few documents so you can start working";
    }

    return "Submit a few documents to qualify to book your first shifts";
  }

  const getSteps = () => {
    // Start with basic steps that are common to all work types
    const allSteps: Array<{
      key: (typeof ONBOARDING_STEPS)[keyof typeof ONBOARDING_STEPS];
      title: string;
      description: string;
      isDone?: boolean;
    }> = [
      {
        key: ONBOARDING_STEPS.PERSONAL_INFORMATION,
        title: "Your personal information",
        description: "We'll ask for your name, email and address",
      },
      {
        key: ONBOARDING_STEPS.PROFESSIONAL_INFORMATION,
        title: "Your professional information",
        description: "Tell us which licenses you have and what type of work you're interested in",
      },
    ];

    // Conditionally add contract and pay for non-PERMANENT_PLACEMENTS
    if (!isPermanentPlacementsOnboarding) {
      allSteps.push({
        key: ONBOARDING_STEPS.CONTRACT_AND_PAY_INFORMATION,
        title: "Your contract and pay information",
        description: "You can get all set up to receive instant payments from Clipboard",
      });
    }

    // Add document step with modified title/description for PERMANENT_PLACEMENTS
    allSteps.push({
      key: ONBOARDING_STEPS.REQUIRED_DOCUMENTS,
      title: isPermanentPlacementsOnboarding ? "Your personal ID" : "Your required documents",
      description: getDocumentsStepDescription(),
    });

    // Add assessment step if applicable and not permanent placement
    if (isScheduledAssessmentAvailableForOnboarding && !isPermanentPlacementsOnboarding) {
      allSteps.push({
        key: ONBOARDING_STEPS.SCHEDULE_SKILL_ASSESSMENT,
        title: "Schedule Your Skills Assessment",
        description: "Pick a time for your final step before booking shifts.",
      });
    }

    //This is to set the isDone property for all steps that are before the active step
    let found = false;

    allSteps.forEach((step) => {
      if (step.key === activeStep) {
        found = true;
      }

      if (!found) {
        step.isDone = true;
      }
    });

    return allSteps;
  };

  const steps = getSteps();

  return (
    <IonRow>
      <IonCol>
        <ul className="cbh-welcome-text infosteps">
          {steps.map((step, index) => (
            <li key={step.key}>
              {step.isDone ? (
                <span>
                  <IonIcon icon={customCheckmark} slot="end" mode="ios" color="medium" />
                </span>
              ) : (
                <span className="step doing">{index + 1}</span>
              )}
              <h4>{step.title}</h4>
              <p>{step.description}</p>
            </li>
          ))}
        </ul>
      </IonCol>
    </IonRow>
  );
}
